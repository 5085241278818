
.sidebar {

    &__layout {}

    &__grid {
        display: grid;
        gap: 3.75rem;
        grid-template-columns: 20.5% 1fr;
        @include r($md) {
            display: block;
        }
    }

}

.page {
    &-sidebar {
        @include r($md) {
            display: none;
            &.fixed {
                position: fixed;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                padding: 15px;
                height: 100vh;
                overflow-y: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #fff;
                z-index: 1000;
            }
        }

        &__header {
            @include r($md) {
                display: block;
                text-align: center;
                width: 100%;
                &::after {
                    content: "Фильтры";
                    @include text20;
                    font-weight: 700;
                    margin-bottom: 20px;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                }
                .page-sidebar__close {
                    display: inline-flex !important;
                    position: absolute;
                    right: 20px;
                    top: 17px;
                }
            }
        }

        &__title {
            font-weight: 700;
            @include text25;
            margin-bottom: 1rem;
            @include r($md) {
                display: none;

            }
        }

    }
}
