.about-page {
    padding: 23px 0 0;
    @include r($sm) {
        margin-top: -41px;
        padding: 51px 0!important;
        overflow: hidden;
    }
    &__text {
        padding: 0;
        p {
            font-size: 20px;
            line-height: 1.85;
            @include r($sm) {
                font-size: 8px;
                line-height: 1.6;
            }
            &:not(:last-child) {
                margin-bottom: 30px;
                @include r($sm) {
                    margin-bottom: 15px;
                }
            }
        }
    }
    &__image {
        transform: translateY(-30%);
        margin-left: auto;
        max-width: 80%;
        display: flex;
        justify-content: flex-end;
        border-radius: 50%;
        overflow: hidden;
        @include r($sm) {
            transform: scale(2) translate(10%,10%);
        }
        &-inner {
            position: relative;
            width: 100%;
            padding-top: 100%;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
