.favorite-empty {
    padding: 10rem 0;
    text-align: center;
    @include r($sm) {
        padding: 2.5rem 0;
    }
    &__title {
        font-weight: 700;
        @include text35;
        margin: 1.5rem 0;
    }
    &__description {
        font-weight: 400;
        @include text16;
        margin: 1.5rem 0 3rem;
    }
    &__btn {
        padding: 1.25rem 3rem;
    }
}
