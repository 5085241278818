// +r(size)
// +r(991)

@keyframes fromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes fromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes scaleIn {
    0% {
        transform: translate(-50%,-50%) scale(0);
    }
    100% {
        transform: translate(-50%,-50%) scale(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loading {
    0% {
        color: #000;
    }
    50% {
        color: rgb(122, 122, 122);
    }
    100% {
        color: #000;
    }
}

@keyframes heroFromLeft {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }
}

@keyframes heroFromRight {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }
}
