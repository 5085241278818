.booking-container {
    margin: 24px 0;
    display: flex;
    gap: 49px;
    @include r($sm) {
        flex-flow: row wrap;
        gap: 23px;
    }
    &__item {
        flex: 0 0 33%;
        @include r($sm) {
            flex: 0 0 100%;
        }
        &-image {
            margin-bottom: 17px;
            border-radius: 8px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        &-heading {
            margin-bottom: 17px;
            font-size: 26px;
            font-weight: 500;
            line-height: 1.3;
            @include r($sm) {
                margin-bottom: 15px;
                font-size: 14px;
            }
        }
        &-subheading {
            margin-bottom: 49px;
            font-size: 17px;
            font-weight: 500;
            line-height: 1.65;
            @include r($sm) {
                margin-bottom: 15px;
                font-size: 10px;
            }
        }
        &-button {

        }
    }
}
