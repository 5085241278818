.main-about {
    position: relative;
    margin: 190px 0;
    padding: 66px 0;
    &__text {
        color: $black;
        font-family: $font-third;
        font-size: 44px;
        font-weight: 500;
        line-height: 1.3;
        text-align: center;
        @include r($sm) {
            max-width: 80vw;
            margin: 0 auto;
            font-size: 12px;
        }
    }
    @include r($sm) {
        margin: 50px 0;
        padding: 33px 0;
    }
    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 170px;
        height: 2px;
        background: $red;
        border-radius: 62px;
    }
    &::before {
        top: 0;
    }
    &::after {
        bottom: 0;
    }
}
