.footer {
    padding: 30px 0;
    background: #F1E6D0;
    @include r($sm) {
        padding: 38px 0;
    }
    &-bottom {
        &__grid {
            display: grid;
            grid-template-columns: 1fr 60% 1fr;
            @include r($sm) {
                display: flex;
                flex-flow: column;
            }
        }
    }
    &-logo {
        @include r($sm) {
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
            max-width: 192px;
            max-height: 83px;
            object-fit: contain;
        }
    }
    &-navbar {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 60px;
        @media (min-width: 767px) and (max-width: 1800px) {
            gap: 40px;
        }
        @include r($sm) {
            margin-bottom: 20px;
            flex-flow: column;
            gap: 20px;
        }
        &__link {
            color: $black;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            transition: .3s ease;
            &:hover {
                color: $lightRed;
            }
            @media (min-width: 1401px) and (max-width: 1700px) {
                font-size: 14px;
            }
            @media (min-width: 767px) and (max-width: 1400px) {
                font-size: 11px;
            }
            @include r($sm) {
                font-size: 12px;
            }
        }
    }
    &-contacts {
        color: #fff;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-end;
        gap: 1rem;
        @include r($sm) {
            flex: 1;
            align-items: center;
            gap: 20px;
        }
        a {
            color: $red;
            font-size: 20px;
            font-weight: 500;
            line-height: 1;
            @media (min-width: 1401px) and (max-width: 1700px) {
                font-size: 16px;
            }
            @media (min-width: 767px) and (max-width: 1400px) {
                font-size: 14px;
            }
            @include r($sm) {
                font-size: 12px;
            }
        }
    }
}
