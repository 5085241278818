$xl: 1359;
$lg: 1199;
$md: 991;
$sm: 767;
$xs: 575;

$white: #FFFFFF;
$black: #232222;
$coral: #AE6137;
$red: #CD1316;
$lightRed: #F0423F;
$transparentRed: rgba(205, 19, 22, 0.2);
