.sidebar-nav {
    margin: 2.25rem 0 1.4rem;
    width: 100%;
    @include r($md) {
        order: 5;
    }
    &__list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        .sidebar-nav__list {
            padding-left: 2rem;
        }
    }
    &__item {
        &_multiline {
            & > .sidebar-nav__link {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

            }
            & > .sidebar-nav__list {
                .sidebar-nav__link {
                    &:not(:hover) {
                        color: #898989;
                    }
                }
            }
        }
        &_active {
            color: $primary;
        }
    }
    &__link {
        position: relative;
        @include text16;
        display: block;
        margin-bottom: 1.6rem;
        padding-right: 2.5rem;
        &_active {
            color: $primary !important;
            .sidebar-nav__toggle svg {
                fill: $primary;
            }
        }
    }
    &__toggle {
        padding: 0.875rem;
        position: absolute;
        right: 0;
        top: 50%;
        min-width: 0.875rem;
        width: 0.875rem;
        height: 0.4375rem;
        min-height: 0.4375rem;
        transition: transform .4s ease;
        transform: translateY(-50%) rotate(180deg);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            min-width: 0.875rem;
            min-height: 0.4375rem;
            display: block;
            fill: #000;
        }
        &.collapsed {
            transform: translateY(-50%) rotate(0deg);
            &:hover {
                transform: translateY(-50%) rotate(0deg);
            }
        }
        &:hover {
            transform: translateY(-50%)  rotate(180deg);
            svg {
                fill: $primary;
            }
        }
    }
}
