.home {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1294px;
    }

    .footer .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1450px;
    }

    .header {
        opacity: 0;
        animation: fadeIn 1s linear 1.6s alternate forwards;
    }
}
