.client-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;
    @include r($md) {
        grid-template-columns: repeat(2, 1fr);
    }
    &__item {
        position: relative;
        &::after {
            content: '';
            background: #C4C4C4;
            padding-bottom: 100%;
            display: block;
            width: 100%;
        }
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 1.25rem * 2);
            height: calc(100% - 1.25rem * 2);
            object-fit: contain;
            transition: transform .3s ease;
        }
        &:hover {
            img {
                transform: translate(-50%, -50%) scale(1.05);
            }
        }
    }
}
