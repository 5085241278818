.gallery-block {
    margin: 118px 0;
    @include r($sm) {
        margin: 36px 0;
    }
    &__title {
        margin-bottom: 43px;
        font-family: $font-third;
        font-size: 39px;
        font-weight: 700;
        line-height: 1.5;
        @include r($sm) {
            margin-bottom: 16px;
            font-size: 18px;
        }
    }
    &__container {
        height: 100%;
        max-height: 542px;
        display: grid;
        grid-template: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 23px;
        & > * {
            display: flex;
            border-radius: 8px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &:nth-child(1) {
                grid-row: 1 / 10;
                grid-column: 1 / 8;
            }
            &:nth-child(2) {
                grid-row: 1 / 5;
                grid-column: 8 / 11;
            }
            &:nth-child(3) {
                grid-row: 1 / 5;
                grid-column: 11 / 13;
            }
            &:nth-child(4) {
                grid-row: 5 / 9;
                grid-column: 8 / 13;
            }
            &.gallery-block__link {
                grid-row: 9 / 10;
                grid-column: 8 / 13;
                position: relative;
                display: flex;
                align-items: flex-end;
                a {
                    color: $lightRed;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1;
                    transition: .3s ease;
                    &:hover {
                        color: $red;
                    }
                    img {
                        max-width: 101px;
                        margin-left: 14px;
                        object-fit: unset;
                    }
                }
            }
        }
        @include r($sm) {
            max-height: 318px;
            grid-template: 1fr 1fr 1fr 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: 9px;
            & > * {
                &:nth-child(1) {
                    grid-row: 1 / 4;
                    grid-column: 1 / 5;
                }
                &:nth-child(2) {
                    grid-row: 1 / 4;
                    grid-column: 5 / 8;
                }
                &:nth-child(3) {
                    grid-row: 4 / 7;
                    grid-column: 1 / 6;
                }
                &:nth-child(4) {
                    grid-row: 4 / 7;
                    grid-column: 6 / 8;
                }
                &.gallery-block__link {
                    grid-row: 7 / 8;
                    grid-column: 1 / 8;
                    align-items: center;
                    @include r($sm) {
                        a{
                            font-size: 10px;
                            img {
                                max-width: 17px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.gallery-block-big {
        margin: 0 0 23px!important;
        @include r($sm) {
            margin: 0 0 9px!important;
        }
        .gallery-block__container {
            max-height: 1084px;
            grid-template: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr!important;
            & > * {
                &:nth-child(1) {
                    grid-row: 1 / 10;
                    grid-column: 1 / 8;
                }
                &:nth-child(2) {
                    grid-row: 1 / 5;
                    grid-column: 8 / 11;
                }
                &:nth-child(3) {
                    grid-row: 1 / 5;
                    grid-column: 11 / 13;
                }
                &:nth-child(4) {
                    grid-row: 5 / 10;
                    grid-column: 8 / 13;
                }
                &:nth-child(5) {
                    grid-row: 10 / 19;
                    grid-column: 6 / 13;
                }
                &:nth-child(6) {
                    grid-row: 10 / 14;
                    grid-column: 3 / 6;
                }
                &:nth-child(7) {
                    grid-row: 10 / 14;
                    grid-column: 1 / 3;
                }
                &:nth-child(8) {
                    grid-row: 14 / 19;
                    grid-column: 1 / 6;
                }
            }
        }

    }
}
