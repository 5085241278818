html,
:root {
  font-size: 12px;
  transition: font-size .15s ease;
  @include rmin(768) {
    font-size: 12px;
  }
  @include rmin(992) {
    font-size: 12px;
  }
	@include rmin(1199) {
    font-size: 14px;
  }
	@include rmin(1300) {
		font-size: 15px;
  }
// 	@include rmin(1360) {
// 		font-size: 16px;
//   }
// 	@include rmin(1700) {
// 		font-size: 21px;
//   }
// 	@include rmin(2300) {
//     font-size: 28px;
//   }
}

html, body {
	height: 100%
}
body {
	background: #FCF9F3;
	@include text16;
	font-family: $font-primary;
	display: flex;
	flex-direction: column;
	line-height: 140%;
	-webkit-font-smoothing: antialiased;
    color: $black;
	// responsive
	// +css-lock(16, 50, 600, 1000)
}

.main {
    max-width: 100vw;
    flex-grow: 1;
    @media (min-width: 992px) and (max-width: 1500px) {
        padding: 0 35px;
    }
}

.home .main .box-widget:last-child {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.box-widget {
    padding: 2.5rem 0;
    + .box-widget {
        padding: 0 0 2.5rem;
        .content {
            margin-top: -2rem;
            padding: 0 0 2rem;
        }
    }
    + .box-widget + .box-widget {
        padding: 2.5rem 0;
        .content {
            margin-top: 0;
        }
    }
    @include r($md) {
        padding: 1.75rem 0;
    }
    &:first-child {
        padding: 15px 0 0;
    }
    &:only-child {
        padding: 15px 0 44px;
    }
    .container > * {
        margin: 1rem 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 0.5rem;
    }
    &__title {
        @include page-title;
    }
    &__link {
        font-weight: 400;
        @include text20;
        display: inline-block;
        margin-bottom: 1.25rem;
        border-bottom: 1px solid $primary;
        color: $primary;
        transition: border-color .15s ease;
        &:hover {
            border-color: transparent;
        }
    }

    &__body {

    }

    &__footer {

    }

    &__content {
        //margin-top: 81px;
        //@media (max-width: 767px) {
        //    margin-top: 22px;
        //}
        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.6;
            @media (max-width: 767px) {
                font-size: 10px;
            }
            &:not(:last-child) {
                margin-bottom: 25px;
            }
            b, strong {
                font-weight: 700;
            }
        }
    }

}

.quantity-counter {
    display: flex;
	user-select: none;
    gap: 2px;
    color: $black;
    text-align: center;
    &__input input {
        width: 2.625rem;
        height: 2.625rem;
        padding: 0.25rem;
        background: transparent;
        border: 1px solid $red;
        border-radius: 1px;
        font-weight: 600;
        @include text16;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: background .2s ease;
        &:hover {
            color: $black;
            background: $transparentRed;
        }
    }
    &__controls {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    &__button {
        padding: 0.25rem;
        width: 1.25rem;
        height: 1.25rem;
        background: transparent;
        border: 1px solid $red;
        border-radius: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        @include text12;
        cursor: pointer;
        transition: background .2s ease, color .2s ease;
        &:hover {
            color: $black;
            background: $transparentRed;
        }
    }
}

.show-more {
    margin-bottom: 50px;
}

.dropdown-menu {
    box-shadow: 0 0.25rem 2.5rem rgba(0, 0, 0, 0.15);
    overflow: hidden;
    min-width: 17.1875rem;
    li:not(:last-child) .dropdown-item {
        border-bottom: 1px solid #F0F0F0;
        &.active {
            border-bottom: 1px solid #006ba8 !important;
        }
        &:hover {
            border-bottom: 1px solid #f0f0f0;
        }
    }
}

.form-check {
    display: flex;
    align-items: center;
    font-weight: 500;
    @include text14;
    color: #00003E;
    &-input {
        margin-top: 0;
        margin-right: 0.5rem;
        &:checked[type=checkbox] {
            background-size: 0.75rem;
        }
    }
    &-label {
        margin-left: 0.6rem;
    }

}

.modal {
    &-booking {
        .modal-content {
            background: #FCF9F3;
            border: none;
            border-radius: 5px;
        }
        .modal-header {
            padding: 21px 21px 0 21px;
        }
        .modal-body {
            padding: 0 30px 25px;
        }
        &__title {
            margin-bottom: 10px;
            color: $coral;
            font-family: $font-third;
            font-size: 39px;
            font-weight: 700;
            line-height: 1.4;
            text-align: center;
        }
        &__subtitle {
            margin-bottom: 32px;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.8;
            text-align: center;
            b {
                font-weight: 700;
            }
        }
        &__form {
            input {
                width: 100%;
                padding: 19px 29px;
                display: block;
                background: $white;
                border: none;
                border-radius: 8px;
                &::placeholder {
                    color: $black;
                }
            }
            .qs-datepicker-container {
                width: 100%;
                max-width: 336px;
                background: $white;
                border: none;
                box-shadow: 0 4px 17px rgba(0, 0, 0, 0.15);
            }
            .qs-controls {
                padding: 12px 0 0;
                justify-content: center;
                align-items: center;
                background: none;
            }
            .qs-month-year {
                pointer-events: none;
                &:hover {
                    text-decoration: none;
                }
            }
            .qs-month {
                padding: 0;
                color: $black;
                font-family: $font-secondary;
                font-size: 16px;
                line-height: 1.45;
            }
            .qs-year {
                display: none;
            }
            .qs-day {
                display: none;
            }
            .qs-squares {
                padding: 16px 60px;
            }
            .qs-square {
                height: 31px;
                color: $black;
                font-size: 16px;
                font-weight: 500;
                line-height: 1;
                border-radius: 50%;
                transition: .3s ease;
            }
            .qs-current {
                text-decoration: none;
            }
            .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover,
            .qs-active {
                background: $lightRed;
                color: $white;
            }
            .qs-arrow {
                width: 30px;
                height: 8px;
                border-radius: none;
                transition: none;
                &:hover {
                    background: inherit;
                }
                &::after {
                    content: unset;
                }
                &.qs-right {
                    margin-left: 9px;
                    background: url("../img/icons/arrow-right.png") center no-repeat;
                }
                &.qs-left {
                    margin-right: 9px;
                    background: url("../img/icons/arrow-left.png") center no-repeat;
                }
            }
            &-acceptance {
                width: 100%;
                max-width: 336px;
                margin: 0 auto;
                padding: 0 5px;
                display: flex;
                align-items: center;
                color: $black;
                font-size: 12px;
                line-height: 2;
                input {
                    width: 19px;
                    height: 19px;
                    margin: 0 9px 0 0;
                    padding: 0;
                }
            }
            &-button {
                display: flex;
                justify-content: center;
            }
        }
    }
    .col-12, .col-6 {
        position: relative;
    }
    .error {
        position: absolute;
        left: 15px;
        bottom: 0;
        width: 100%;
        color: #bb1d1d;
        font-size: 8px;
    }
    .col-6 .error {
        left: 8px;
    }
    .success {
        margin-top: 25px;
        font-size: 18px;
        line-height: 1.5;
        text-align: center;
    }
}

.custom-select {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 336px;
    margin: 0 auto;
    padding: 19px 29px;
    background: $white;
    border: none;
    border-radius: 8px;
    &.empty {
        opacity: .5;
        pointer-events: none;
    }
    .error {
        order: 999;
    }
    select {
        display: none;
    }
    .select-selected {
        position: relative;
        cursor: pointer;
    }
    .select-items {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 130px;
        padding: 5px 28px;
        background: $white;
        box-shadow: 0 4px 17px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        transform: scaleY(1);
        transform-origin: top;
        opacity: 1;
        visibility: visible;
        overflow-y: auto;
        transition: .3s ease;
        z-index: 4;
        &::-webkit-scrollbar {
            width: 5px;
            background: $white;
        }
        &::-webkit-scrollbar-thumb {
            background: #ccc;
        }
        div {
            width: 100%;
            padding: 12px 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            text-align: center;
            cursor: pointer;
            opacity: .4;
            transition: .3s ease;
            &:hover {
                background: #FCF9F3;
                opacity: 1;
            }
        }
        &.select-hide {
            transform: scaleY(0);
            opacity: 0;
            visibility: hidden;
        }
    }
}

.badge {
    font-weight: 500;
    @include text12;
    padding: 0.375rem 0.625rem;
    &_active {
        background: #1ED300;
    }
}

.form-check-input[type="radio"] {
    &:checked {
        background-color: #00003E !important;
        border-color: #00003E !important;
    }
}
.form-check-input[type="radio"] + label {
    @include text16;
    color: #00003E;
    span {
        display: block;
        font-size: 10px;
        line-height: 170.1%;
        color: #00003E;
    }
}

.choices__inner {
    background: #FFFFFF;
    border: 1px solid #ced4da;
    // border: 1px solid #AAC0D7;
    @include text16;
    border-radius: 5px !important;
    padding: 0.46875rem 1.25rem 0.234375rem;
    display: flex;
    align-items: center;
}

.choices__list--dropdown {
    box-shadow: 0px 4px 40px rgba(0,0,0, 0.15);
    border-radius: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border: 0 !important;
}
.choices__list--dropdown .choices__item {
    color: #006BA8;
    border-bottom: 1px solid #F0F0F0;
    padding: 0.625rem 1.5rem !important;
}

.form-select-lg + .choices__list {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    @include text16;
    padding-left: 0;
}

.container .container {
    padding-left: 0;
    padding-right: 0;
}

body:not(.ready) .mmenu__list {
    display: none !important;
}

.btn-warning {
    color: #fff;
}

.preloader {
    position: relative;
    z-index: 3000;
}

.preloader::before {
    content: '';
    background: rgba(255,255,255,0.8);
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.preloader::after {
    content: 'Идет загрузка...';
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 40px;
    font-size: 1.125rem;
    text-align: center;
    animation: loading 1s ease infinite;
}

.block-heading {
    margin-bottom: 26px;
    color: $coral;
    font-family: $font-third;
    font-size: 61px;
    font-weight: 700;
    line-height: 1.3;
    @include r($sm) {
        margin-bottom: 17px;
        font-size: 22px;
    }
}

@mixin btn-default {
    padding: 21px 40px;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.3;
    @include r($sm) {
        padding: 11px 19px;
        font-size: 10px;
    }
}

.btn {
    border-radius: 62px;
    transition: .3s ease;
    &-red,
    &-secondary {
        @include btn-default;
        background: $red;
        color: $white;
        border: none;
        &:hover {
            color: $white;
            background: $lightRed;
            box-shadow: 0 0 14px rgba(240, 66, 63, 0.76);
        }
    }
    &-transparent,
    &-primary {
        @include btn-default;
        background: transparent;
        color: $black;
        border: 2px solid $red;
        &:hover {
            color: $black;
            background: $transparentRed;
        }
    }
}
