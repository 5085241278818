.contacts {
    padding-bottom: 0;
    .contacts-block__mobile-container {
        display: none;
        @include r($sm) {
            display: flex;
        }
    }
    &__item {
        text-align: center;
        @include r($sm) {
            text-align: left;
        }
        &-icon {
            margin-bottom: 27px;
            svg {
                max-width: 30px;
                max-height: 30px;
            }
            @include r($sm) {
                display: none;
            }
        }
        &-text {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.7;
            @include r($sm) {
                font-size: 12px;
                margin-bottom: 15px;
            }
            br {
                @include r($sm) {
                    display: none;
                }
            }
            a {
                color: $lightRed;
                transition: .3s ease;
                &:hover {
                    color: $red;
                }
            }
        }
        &-email {
            a {
                text-decoration: underline;
            }
        }
    }
}

.contacts-map {
    margin-top: 58px;
    @include r($sm) {
        margin-top: 26px;
    }
    #map {
        height: 550px;
        @include r($sm) {
            height: 270px;
        }
    }
}
