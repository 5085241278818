.hero__slider {
    --swiper-theme-color: rgba(255,255,255,0.5);
    --swiper-navigation-color: #fff;
    --swiper-navigation-size: 80px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 2000;
    transition: 1.3s ease;
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        &.from-left {
            animation: heroFromLeft 1.3s ease alternate;
        }
        &.from-right {
            animation: heroFromRight 1.3s ease alternate;
        }
    }
    .swiper-pagination {
        position: absolute;
        top: 3%;
        left: 3%;
        right: 3%;
        width: 94%;
        height: 4px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 24px;
        z-index: 3;
        @include r($md) {
            gap: 8px;
        }
        .swiper-pagination-bullet {
            flex: 1 1 100%;
            background: $white;
            border-radius: 52px;
            opacity: .3;
            transition: .3s ease;
            &-active,
            &:hover {
                opacity: .7;
            }
        }
    }
    .swiper-button-prev {
        left: 3%;
        @include r($md) {
            display: none;
        }
    }
    .swiper-button-next {
        right: 3%;
        @include r($md) {
            display: none;
        }
    }
    .close-slider {
        position: absolute;
        top: 7%;
        right: 3%;
        width: 40px;
        height: 40px;
        z-index: 3;
        & > * {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 2px;
            background: $white;
            z-index: 6;
            cursor: pointer;
            &:first-child {
                transform: translate(-50%,-50%) rotate(45deg);
            }
            &:last-child {
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }
    }
    .swiper-slide {
        max-height: 100vh;
        overflow-y: auto;
    }
    .slide {
        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 2;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $black;
                opacity: .8;
                z-index: 3;
            }
        }
        &-content {
            position: relative;
            height: 100%;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            gap: 50px;
            color: $white;
            text-align: center;
            z-index: 5;
            @include r($md) {
                gap: 16px;
                padding: 0 24px;
            }
            &__heading {
                max-width: 1000px;
                font-family: $font-secondary;
                font-size: 125px;
                line-height: 1;
                @include r($md) {
                    font-size: 32px;
                }
            }
            &__description {
                max-width: 532px;
                font-family: $font-secondary;
                font-size: 21px;
                line-height: 1.3;
                @include r($md) {
                    font-size: 14px;
                }
            }
            &__buttons {
                display: flex;
                gap: 24px;
                @include r($md) {
                    margin-top: 100px;
                    flex-flow: column;
                    gap: 16px;
                }
                & > * {
                    color: $white;
                }
            }
        }
    }

}

.swiper-button-prev {
    left: 1.25rem;
}
.swiper-button-next {
    right: 1.25rem;
}

@include r($lg) {
    :root {
        --swiper-navigation-size: 2.5rem;
    }
}
