.hero-block {
    position: relative;
    height: calc(100vh - 220px);
    margin-top: -110px;
    min-height: 850px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url("../img/hero-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    overflow: hidden;
    z-index: 1;
    @include r($sm) {
        padding-bottom: 86px;
        height: auto;
        margin-top: -10px;
        min-height: auto;
        background: unset;
    }
    @media (min-width: 767px) and (max-width: 1500px) {
        min-height: 650px;
        margin-left: -35px;
        margin-right: -35px;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
    }
    &__left,
    &__right,
    &__title {
        position: relative;
        flex: 0 1 33%;
    }
    &__left,
    &__right {
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: .6s linear;
        &:hover {
            flex: 1 0 37%;
        }
    }
    $heroOffset: -8%;
    &__left {
        left: $heroOffset;
        animation: fromLeft 1.3s linear alternate;
    }
    &__right {
        right: $heroOffset;
        animation: fromRight 1.3s linear alternate;
    }
    &__inner {
        padding-top: 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        flex-shrink: 0;
        padding: 118px 0;
        color: $black;
        font-family: $font-third;
        font-size: 125px;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        @include r($sm) {
            padding: 20px 0;
            font-size: 25px;
        }
        @media (min-width: 767px) and (max-width: 1500px) {
            font-size: 80px;
        }
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 97%;
            padding-top: 100%;
            background: #F3EEE4;
            border-radius: 50%;
            z-index: -1;
            animation: scaleIn 1.3s linear alternate;
        }
        div {
            padding: 20px 0;
            overflow-y: hidden;
            span {
                display: block;
                animation: slideIn 1.3s linear alternate;
            }
        }
    }
    &__buttons {
        position: absolute;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        opacity: 0;
        animation: fadeIn 1s linear 1.6s alternate forwards;
        @include r($sm) {
            bottom: 0;
        }
    }
}
