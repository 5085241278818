.product-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 46px;
    @include r($sm) {
        grid-template-columns: repeat(2,1fr);
        gap: 20px 12px;
    }
    &_col-6 {
        grid-template-columns: repeat(6, 1fr);
        gap: 2.5rem 0;
        @include r($lg) {
            grid-template-columns: repeat(5,1fr);
        }
        @include r($md) {
            grid-template-columns: repeat(4,1fr);
        }
        @include r($sm) {
            grid-template-columns: repeat(2,1fr);
        }
        @include r($sm) {
            gap: 1rem;
        }

    }
    &_col-4 {
        grid-template-columns: repeat(4, 1fr);
        @include r($md) {
            grid-template-columns: repeat(3,1fr);
        }
        @include r($sm) {
            grid-template-columns: repeat(2,1fr);
            gap: 1rem;
        }
    }
    &_row {
        grid-template-columns: 1fr;
        gap: 1.25rem 1.25rem;
        @include r($sm) {
            gap: 1rem;
        }
    }
}
