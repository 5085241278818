.category-list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 50px;
    @include text16;
    font-weight: 500;
    @include r($sm) {
        grid-template-columns: repeat(2,1fr);
        gap: 17px;
    }
    &_sm {
        @include text14;
        @include r($md) {
            grid-template-columns: repeat(4,1fr);
        }
        @include r($sm) {
            grid-template-columns: repeat(2,1fr);
        }
    }
    &__item {
        position: relative;
        height: 299px;
        border-radius: 0.8125rem;
        overflow: hidden;
        @include r($sm) {
            height: 101px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .5s ease;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $black;
            opacity: .5;
            z-index: 2;
            transition: .5s ease;
        }
        &:hover {
            img {
                transform: scale(1.15);
            }
            &::after {
                background: $black;
                opacity: .2;
            }
        }
        &-content {
            position: absolute;
            left: 44px;
            bottom: 25px;
            padding: 0;
            color: $white;
            font-family: $font-secondary;
            font-size: 33px;
            font-weight: 700;
            line-height: 1;
            z-index: 3;
            @media (max-width: 767px) {
                left: 11px;
                bottom: 11px;
                font-size: 13px;
            }
        }
    }
}
