.header {
    position: relative;
    z-index: 5;
    @include r($md) {
        display: none;
    }
    &-top {
        max-width: 1920px;
        padding: 30px 32px;
        display: grid;
        grid-template-columns: 1fr 50% 1fr;
        gap: 1rem;
        align-items: center;
        @media (min-width: 992px) and (max-width: 1800px) {
            grid-template-columns: 1fr 65% 1fr;
        }
    }

    &-right {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 1401px) and (max-width: 1700px) {
            font-size: 14px;
        }
        @media (min-width: 992px) and (max-width: 1400px) {
            font-size: 11px;
        }

        &__booking {
            color: $black;
            font-weight: 700;
            cursor: pointer;
            transition: .3s ease;
            &:hover {
                color: $lightRed;
            }
        }
    }

    &-logo {
        img {
            width: 100%;
            height: 100%;
            max-width: 192px;
            max-height: 83px;
            object-fit: contain;
        }
    }

    &-navbar {
        display: flex;
        justify-content: center;
        gap: 48px;
        &__link {
            color: $black;
            font-size: 17px;
            font-weight: 700;
            line-height: 1;
            transition: .3s ease;
            &:hover {
                color: $lightRed;
            }
            @media (min-width: 1401px) and (max-width: 1700px) {
                font-size: 14px;
            }
            @media (min-width: 767px) and (max-width: 1400px) {
                font-size: 11px;
            }
        }
    }

    &-contacts {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        a {
            color: $black;
            transition: .3s ease;
            &:hover {
                color: $lightRed;
            }
        }
        @media (min-width: 992px) and (max-width: 1800px) {
            flex-flow: column;
            gap: 1rem;
            *:first-child {
                order: 2;
            }
        }
    }

    &-phone {
        font-weight: 700;
    }

    &-controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4.5rem;
        &__item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: bold;
            svg {
                width: 24px;
                height: 28px;
                transition: .3s ease;
                @include r($sm) {
                    width: 15px;
                    height: 17px;
                }
            }
            &:hover {
                svg {
                    fill: $lightRed;
                }
            }

            &_counter {
                $size: 20px;
                position: absolute;
                top: -9px;
                right: -9px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: $size;
                height: $size;
                background: $lightRed;
                color: #fff;
                font-size: 12px;
                line-height: 1;
                text-align: center;
                border-radius: 50%;
                overflow: hidden;
                @include r($sm) {
                    $size: 12px;
                    width: $size;
                    height: $size;
                    font-size: 9px;
                }
            }
        }
    }
}

.offcanvas {
    &-catalog {
        width: 100%;
        max-width: 85.625rem;
        .offcanvas-body, .offcanvas-header {
            padding: 1.375rem 3.0625rem;
        }
        .offcanvas-header {
            display: grid;
            grid-template-columns: 1.6fr 3fr;
            &__controls {
                display: flex;
                justify-content: space-between;
                .header-search__input {
                    border-radius: 0;
                    border-left: 0;
                    border-top: 0;
                    border-right: 0;
                    padding-left: 0;
                    max-width: 41.1875rem;
                }
            }
        }
        .btn-close {
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
        }
        &__title {
            font-weight: 700;
            @include text25;
            color: #00274B;
            margin-bottom: 2rem;
        }
        &__list {
            display: grid;
            grid-template-columns: 1.6fr 3fr;
        }
        &__nav {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 0.9375rem;
            button {
                display: flex;
                align-items: center;
                background: transparent;
                width: 100%;
                font-weight: 400;
                @include text14;
                color: #454545;
                position: relative;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 1.625rem;
                    height: 0.4375rem;
                    margin-left: 1.5625rem;
                    opacity: 0;
                    transform: translateX(-0.9375rem);
                    transition: opacity .2s ease, transform .2s ease;
                    background: url('./../../../img/icons/arrow.svg');
                }
                &:hover {
                    &::after {
                        opacity: 1;
                        transform: none;
                    }
                }
                &.active {
                    font-weight: 600;
                }
                img {
                    margin-right: 1.875rem;
                    width: 1.875rem;
                    height: 1.875rem;
                    object-fit: contain;
                }
            }
            &-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                @include text14;
            }
            &-link {
                margin-bottom: 1.5rem;
            }
        }
    }
}

.mm-spn.mm-spn--navbar:after {
    content: none;
}

.mm-spn.mm-spn--light {
    background: #fcf9f3;
}

.mm-spn.mm-spn--navbar ul {
    top: 50px;
}

.mobile-header {
    display: none;
    position: sticky;
    top: 0;
    background: #FCF9F3;
    padding: 7px 0;
    z-index: 1000;
    @include r($md) {
        display: block;
    }
    &__grid {
        display: grid;
        grid-template-columns: 0.8fr 1.4fr 0.4fr 0.4fr;
        align-items: center;
    }
    &__toggle {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-end;
        gap: 5px;
        div {
            width: 21px;
            height: 3px;
            background: $red;
            border-radius: 60px;
        }
    }
    &__search {
        padding: 2rem 0 1rem;
        @include r($sm) {
            padding: 1.25rem 0 0.75rem;
        }
    }
    .header-controls {
        @include r($sm) {
            font-size: 0;
            gap: 1.75rem;
        }
    }
}

.mmenu__header {
    .header-contacts {
        display: flex;
        flex-direction: column;
        gap: 0;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 10px 15px;
    }
    .header-phone {
        padding: 5px 0;
    }
    .dropdown-menu {
        min-width: auto;
        left: auto !important;
    }
}
