.content-image-block {
    margin-bottom: 46px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    gap: 100px;
    @include r($sm) {
        margin-bottom: 30px;
        padding: 0;
        gap: 14px;
    }
    &-about {
        max-width: 1294px;
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
        .content-image-block__text {
            .text-description {
                padding-bottom: 38px!important;
                @include r($sm) {
                    padding-bottom: 16px;
                }
                & + .text-description {
                    margin-top: 38px;
                    @include r($sm) {
                        margin-top: 16px;
                    }
                }
            }
            .text-heading {
                @include r($sm) {
                    font-size: 20px;
                }
            }
            .text-button {
                margin-top: 51px;
                @include r($sm) {
                    margin-top: 21px;
                }
            }
        }
        .content-image-block__image {
            .image-container {
                padding-top: 100%;
                @include r($sm) {
                    padding-top: 150%;
                }
            }
        }
    }
    & > * {
        flex: 0 0 calc(50% - 50px);
        @include r($sm) {
            flex: 0 0 calc(50% - 7px);
        }
    }
    &__text {
        display: flex;
        flex-flow: column;
        justify-content: center;
        .text-heading {
            max-width: 468px;
            margin-bottom: 32px;
            color: $black;
            font-family: $font-third;
            font-size: 44px;
            font-weight: 500;
            line-height: 1.3;
            @include r($sm) {
                margin-bottom: 13px;
                font-size: 12px;
            }
        }
        .text-description {
            position: relative;
            padding-bottom: 70px;
            font-size: 17px;
            font-weight: 500;
            line-height: 1.9;
            @include r($sm) {
                padding-bottom: 23px!important;
                font-size: 8px;
            }
            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 57px;
                height: 2px;
                background: $red;
                border-radius: 62px;
            }
            & + .text-description {
                margin-top: 38px;
                padding-bottom: 0!important;
                &::after {
                    content: unset;
                }
            }
        }
        .text-button {
            margin-top: 51px;
        }
    }
    &:nth-child(n+2) {
        .content-image-block__text {
            order: 1;
        }
    }
    &__image {
        .image-container {
            position: relative;
            padding-top: 100%;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
