.menu {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 48px;
    @include r($sm) {
        grid-template-columns: 1fr;
        gap: 18px;
    }
    &__item {
        padding: 23px;
        text-align: center;
        border: 2px solid $coral;
        transition: .3s ease;
        @include r($sm) {
            padding: 11px;
        }
        &:hover {
            border-color: $lightRed;
            box-shadow: 0 0 14px rgba(240, 66, 63, 0.76);
            .menu__item-icon svg path {
                fill: $lightRed;
            }
            .menu__item-heading,
            .menu__item-subheading {
                color: $lightRed;
            }
        }
        &-icon {
            margin-bottom: 21px;
            @include r($sm) {
                margin-bottom: 0;
            }
            svg {
                max-width: 53px;
                @include r($sm) {
                    max-width: 25px;
                }
                path {
                    fill: $coral;
                }
            }
        }
        &-heading {
            color: $coral;
            font-family: $font-secondary;
            font-size: 27px;
            font-weight: 700;
            line-height: 1.1;
            @include r($sm) {
                font-size: 18px;
            }
        }
        &-subheading {
            color: $black;
            font-size: 17px;
            line-height: 2.2;
            @include r($sm) {
                font-size: 13px;
            }
        }
    }
}

.menu-widgets {
    max-width: 1294px;
    margin: 100px auto 180px;
    @include r($sm) {
        margin: 40px auto;
    }
}
