.product-item {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;
    @include r($sm) {
        padding: 0;
    }
    &__figure {
        display: block;
        position: relative;
        margin: 0 0 10px;
    }
    &__body {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    &__image {
        width: 100%;
        height: 250px;
        transform: none;
        border-radius: 8px;
        overflow: hidden;
        @include r($sm) {
            height: 112px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__name {
        width: fit-content;
        margin: 0 0 15px;
        display: flex;
        color: $black;
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 1.3;
        cursor: default;
        transition: .3s ease;
        &:hover {
            color: $red;
        }
        @include r($sm) {
            font-size: 10px;
        }
    }
    &__weight {
        margin: 0 0 5px;
        color: $coral;
        font-family: $font-secondary;
        font-size: 14px;
        line-height: 1;
    }
    &__options {
        margin: 0 0 15px;
        display: flex;
        justify-content: space-between;
        @include r($xl) {
            gap: 2rem;
        }
        @include r($sm) {
            margin: 0 0 10px;
        }
    }
    &__price {
        color: $black;
        font-size: 34px;
        font-weight: 700;
        line-height: 1.5;
        @include r($sm) {
            font-size: 14px;
        }
        i {
            @include text16;
            font-style: normal;
            font-weight: 700;
            margin-left: 3px;
        }
    }
    &__footer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    &__btn {
        margin-top: auto;
        max-width: 226px;
        @include red-button;
        @include r($sm) {
            max-width: 113px;
            padding: 10px;
            font-size: 10px;
        }
    }
    // view row
    &_row {
        display: grid;
        gap: 2rem;
        grid-template-columns: 9.375rem 1fr 1.25fr;
        @include r($md) {
            grid-template-columns: 9.375rem 1fr 1fr;
        }
        @include r($sm) {
            display: flex;
            flex-wrap: wrap;
            gap: 10px 20px;
            flex-direction: row;
            .product-item__figure {
                width: 80px;
                height: 80px;
            }
            .product-item__body {
                width: calc(100% - 80px - 20px)
            }
            .product-item__options {
                align-items: center;
            }
        }
        .product-item__options {
            margin: 0;
            @include r($sm) {
                padding-left: 100px;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
        .product-item__image {
            height: auto;
        }

        .product-item__body {
            display: flex;
            align-items: center;
        }
        .product-labels {
            margin: 0.25rem 0 0.5rem;
            min-height: 1.8rem;
            flex-wrap: wrap;
        }
        .product-item__footer {
            display: grid;
            grid-template-columns: 1fr 0.8fr;
            align-items: flex-start;
            gap: 2.5rem;
            margin-top: 7%;
            @include r($sm) {
                margin-top: 0.5rem;
                align-items: center;
                gap: 10px;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 30px;
            }
        }
        .product-item__btn {
            margin-top: 0;
            @include r($sm) {
                width: 100%;
            }
        }
    }
}

.order__col {
    .product-labels,
    .product-item__code {
        display: none;
    }

    .product-item__name {
        margin-bottom: 0;
    }

    .product-item__body {
        justify-content: center;
        align-items: flex-start;
    }

    .product-item_row .product-item__footer {
        margin-top: 0;
    }

    .product-item_row {
        align-items: center;
    }

    .product-item__figure {
        margin: 0;
    }
}
@include r($sm) {
    $gap: 15px;
    $fwidth: 100px;
    .order__col {
        .product-list_row {
            gap: 15px;
        }
        .product-item_row {
            position: relative;
            gap: $gap;
            flex-flow: row nowrap;

            .product-item__body {
                flex: 0 0 140px;
                width: unset;
                height: 100%;
                align-items: flex-start;
                justify-content: flex-start;
                flex-flow: row nowrap;
            }

            .product-item__footer {
                margin: 0;
                flex-flow: row nowrap;
                justify-content: space-between;

                .product-item__options {
                    padding-left: 0;
                    width: unset;
                    flex-flow: row nowrap;

                    .product-item__price {
                        position: absolute;
                        left: $fwidth + $gap;
                        bottom: 0;
                        font-size: 13px;

                        i {
                            font-size: 10px;
                        }
                    }
                }

                .product-cart__remove {
                    margin: 0;
                    padding: 0;
                    height: unset;
                    color: #000!important;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 1;
                    opacity: 1!important;
                    &::after {
                        content: "X";
                        text-decoration: none;
                    }
                }
            }

            .product-item__figure {
                margin: 0;
                flex: 0 0 $fwidth;
                height: 100%;
                min-height: 70px;

                .product-item__image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
