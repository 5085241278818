@include font('Montserrat', 'Montserrat-Regular', normal);
@include font('Montserrat', 'Montserrat-Medium', 500);
@include font('Montserrat', 'Montserrat-SemiBold', 600);
@include font('Montserrat', 'Montserrat-Bold', 700);
@include font('Montserrat', 'Montserrat-Black', 900);
@include font('Philosopher', 'Philosopher', normal);
@include font('Philosopher', 'Philosopher-Bold', 700);
@include font('PlayfairDisplay', 'PlayfairDisplay-Medium', 500);
@include font('PlayfairDisplay', 'PlayfairDisplay-SemiBold', 600);
@include font('PlayfairDisplay', 'PlayfairDisplay-Bold', 700);


$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Philosopher';
$font-third: 'PlayfairDisplay';

// only rem

@mixin text12 {
    font-size: .75rem;
    line-height: 130%;
}
@mixin text14 {
    font-size: .875rem;
    line-height: 130%;
}
@mixin text16 {
    font-size: 1rem;
    line-height: 140%;
}
@mixin text20 {
    font-size: 1.25rem;
    line-height: 130%;
}
@mixin text22 {
    font-size: 1.375rem;
    line-height: 130%;
}
@mixin text25 {
    font-size: 1.5625rem;
    line-height: 130%;
}
@mixin text35 {
    font-size: 2.1875rem;
    line-height: 133.1%;
    @include r($lg) {
        font-size: 2rem;
    }
    @include r($sm) {
        @include text25;
    }
}
@mixin text45 {
    font-size: 2.8125rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text35;
    }
}
@mixin text55 {
    font-size: 3.4375rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text45;
    }
}
@mixin text65 {
    font-size: 4.0625rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text45;
    }
}

@mixin page-title {
    position: relative;
    margin-bottom: 58px;
    color: $coral;
    font-family: $font-third;
    font-size: 61px;
    font-weight: 700;
    line-height: 1.3;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -26px;
        width: 57px;
        height: 2px;
        background: $red;
        @media (max-width: 767px) {
            bottom: -18px;
        }
    }
    @media (max-width: 767px) {
        margin-bottom: 36px;
        font-size: 20px;
    }
}

@mixin contentText {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.8;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.content {
    color: $black;
    padding: 2rem 0;
    @include r($sm) {
        @include text25;
        padding: 2rem 0 0;
    }
    h1 {
        font-weight: 700;
        @include text55;
        margin-bottom: 2.75rem;
        @include r($md) {
            @include text45;
        }
    }
    h2 {
        font-weight: 700;
        @include text45;
        margin-bottom: 2.25rem;
        @include r($md) {
            @include text35;
        }
    }
    p {
        @include contentText;
        b, strong {
            font-weight: 700;
        }
    }
    li {
        @include contentText;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    ul {
        li {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: -35px;
                width: 24px;
                height: 24px;
                background: url("../../img/icons/list-pin.png") center no-repeat;
            }
        }
    }
    ol {
        list-style-type: decimal;
        li {
            &::marker {
                color: $red;
                font-weight: 600;
            }
        }
    }
}
