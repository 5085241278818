.form-inline {
    margin: 5rem 0 3rem;
    max-width: 68rem;
    @include r($sm) {
        max-width: 100%;
        margin: 1.5rem 0;
        button {
            width: 100%;
        }
    }
}
