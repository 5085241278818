.catalog-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include text16;
    padding: .25rem 0 2.75rem;
    @include r($sm) {
        flex-direction: column;
        align-items: flex-start;
    }
    &__sort {
        display: flex;
        align-items: center;
        &-link {
            color: #1B8DF4;
            margin-left: 0.3rem;
        }
        @include r($sm) {
            margin-bottom: 0.75rem;
        }
    }
    &__type {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.views {
    &-list {
        display: flex;
        gap: 0.875rem;
        margin-left: 2rem;
        svg {
            fill: #0D6EC8;
            width: 1.4375rem;
            height: 1.4375rem;
        }
        &__item {
            opacity: 0.5;
            &.active {
                opacity: 1;
            }
        }
    }
}

.catalog-filter__toggle {
    display: none;
    align-items: center;
    justify-content: flex-start;
    @include r($md) {
        display: inline-flex;
        @include text16;
        svg {
            width: 18px;
            height: 18px;
            margin-right: 15px;
        }
    }
    @include r($sm) {
        margin-bottom: 20px;
    }
}
