.contacts-block {
    position: relative;
    background: #F1E6D0;
    padding-bottom: 34px;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 20px);
        height: 100%;
        background: #f1e6d0;
        z-index: -1;
    }
    .box-widget {
        padding: 15px 0 0;
    }
    @include r($sm) {
        padding-bottom: 0;
    }
    &__address {
        font-size: 20px;
        font-weight: 500;
        line-height: 2.2;
        @include r($sm) {
            font-size: 12px;
        }
    }
    &__links {
        margin-bottom: 17px;
        display: flex;
        flex-flow: column;
    }
    &__phone,
    &__email {
        width: fit-content;
        color: $red;
        font-size: 20px;
        font-weight: 500;
        line-height: 2.2;
        transition: .3s ease;
        @include r($sm) {
            font-size: 12px;
            font-weight: 600;
        }
        &:hover {
            color: $coral;
        }
    }
    &__socials {
        display: flex;
        align-items: center;
        gap: 16px;
        img {
            max-width: 47px;
            max-height: 30px;
        }
    }
    &__button {
        margin-top: 118px;
        @include r($sm) {
            margin-top: 0;
        }
    }
    &__mobile-container {
        @include r($sm) {
            margin: 30px 0 40px;
            display: flex;
            gap: 22px;
        }
    }
    &__map {
        height: 100%;
        min-height: 180px;
        border-radius: 8px;
        overflow: hidden;
        @include r($sm) {
            margin: 0 -15px;
        }
        .main-map {
            height: 100%;
        }
    }
}

.reviews-embed {
    margin-top: 79px;
    border-radius: 8px;
    overflow: hidden;
    @include r($sm) {
        margin-top: 21px;
    }
}


