.show-more {
    &__button {
        font-weight: 600;
        @include text16;
        text-align: center;
        padding: 1.25rem;
        text-decoration-line: underline;
        color: $red;
        display: block;
        width: 100%;
        background: transparent;
        transition: background .3s ease;
        &:hover {
            background: $transparentRed;
        }
    }
}
