.callback-block {
    background: #C4C4C4;
    padding: 2rem 1.75rem 1rem;
    &__title {
        @include text55;
        font-weight: 700;
        margin: 1.75rem 0;
    }
    &__description {
        font-weight: 400;
        @include text20;
        margin: 1.75rem 0;
    }
}
